import React from 'react';
import flashMessage from '../../../apps/flash_message/helpers/get_flash_message';
import ShareText from './share_text';
import copyTextToClipboard from '../../../helpers/copyTextToClipboard';

const copyLink = ({ text, input, copyText, onClickCallback = null }) => {
  if (onClickCallback) {
    onClickCallback();
  }
  const inputToCopy = document.querySelector(input);
  const copy = () => {
    if (inputToCopy) {
      inputToCopy.select();
      document.execCommand('copy');
      return flashMessage.showSuccessMessage('Link copied!');
    }
    if (copyText) {
      copyTextToClipboard({
        text: copyText,
        successCallback: console.log('copied from share fallback!'),
      });
      return flashMessage.showSuccessMessage('Link copied!');
    }
    return false;
  };
  const shareText = () =>
    text && typeof text === 'boolean' ? (
      <ShareText text={'Copy link'} />
    ) : text && typeof text === 'string' ? (
      <ShareText text={text} />
    ) : null;
  return (
    <div className="share-button">
      <button
        type="button"
        className="share-button__button share-button__button--copy-link"
        onClick={(e) => copy(e)}
      >
        <div className="share-button__icon">
          <i className="far fa-clone" />
        </div>
        {shareText()}
      </button>
    </div>
  );
};

export default copyLink;
