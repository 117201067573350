export default function createMapObject(map) {
  // Very rudimentary, but possibly helpful?
  if (!(map === Object(map)) || typeof map === 'function') {
    const type = typeof map;
    throw new Error(
      `The map instance passed to createMapObject much be of type object. You passed a ${type}.`,
    );
  }
  return {
    map,
    markers: {},
    infoWindow: {
      instance: null,
    },
  };
}
