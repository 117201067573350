import showRegPromptAfterOneMinute from '../../apps/reg_prompt/afterOneMinute';
import showRegPromptAfterTwoSeconds from '../../apps/reg_prompt/afterTwoSeconds';
import showRegPromptAfterViews from '../../apps/reg_prompt_gated/afterViews';
import { fetchData } from '../../helpers/base_data_fetcher';

const triggerRegistrationPrompt = async () => {
  try {
    const [isLoggedIn, affiliate, features, isMobile] = await Promise.all([
      fetchData('session.loggedin'),
      fetchData('affiliate'),
      fetchData('config.features'),
      fetchData('site.mobile'),
    ]);
    if (isLoggedIn) {
      return false;
    }
    const searchParam = new URLSearchParams(window.location.search);
    let aggregatorName = '';
    if (searchParam.has('utm_source')) {
      aggregatorName = searchParam.get('utm_source').toLowerCase();
    }
    if (aggregatorName.includes('trovit')) {
      return showRegPromptAfterTwoSeconds('Trovit');
    }
    const { blocking_reg_prompt, one_minute_reg_prompt } = features;
    if (affiliate.category === 'adwords' && blocking_reg_prompt && !isMobile) {
      return showRegPromptAfterViews();
    }

    if (affiliate.category !== 'adwords' && one_minute_reg_prompt) {
      return showRegPromptAfterOneMinute();
    }
    return false;
  } catch (error) {
    return console.log(error);
  }
};
export default triggerRegistrationPrompt;
