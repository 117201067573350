async function load({ token = '', domain = 'www.spareroom.co.uk' } = {}) {
  if (window.google && window.google.maps) {
    return window.google.maps;
  }
  try {
    await createGoogleMapsTag({
      channel: domain,
    });
  } catch (error) {
    return error;
  }
  return window.google.maps;
}

function createGoogleMapsTag({
  // language = 'en_gb',
  channel = 'www.spareroom.co.uk',
  clientId = 'gme-flatshareltd',
} = {}) {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?client=${clientId}&channel=${channel}&v=quarterly`;
    scriptTag.id = 'google_maps-tag';
    scriptTag.defer = true;
    scriptTag.onerror = () => reject(new Error('Google MAps failed to load'));
    scriptTag.onload = () => resolve();
    return document.head.appendChild(scriptTag);
  });
}

export default load;
