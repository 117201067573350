import Cookies from 'js-cookie';
import regPrompt from '.';
import { fetchData } from '../../helpers/base_data_fetcher';

const twoSeconds = 2000;

function isMoreThanTwoSeconds(timeStamp) {
  const now = new Date().getTime();
  if (now - parseInt(timeStamp, 10) > twoSeconds) {
    return false;
  }
  return true;
}

export default async function(headingText) {
  const userId = Cookies.get('user_id');
  const alreadyShown = Cookies.get('reg_prompt_shown');
  const timeOnSiteCookie = Cookies.get('time_arrived_stamp');
  const tooSoon = timeOnSiteCookie
    ? isMoreThanTwoSeconds(timeOnSiteCookie)
    : true;
  if (userId || alreadyShown || tooSoon) {
    return false;
  }
  const options = { expires: 2 };
  try {
    const domain = await fetchData('site.domain');
    if (domain) {
      options.domain = domain;
    }
    Cookies.set('reg_prompt_shown', 1, options);
    return regPrompt(headingText);
  } catch (error) {
    return console.log(error);
  }
}
