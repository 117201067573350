import get from '../../../helpers/data_layer/get';

const isMobile = get('site.mobile');

const sharePlatforms = [
  {
    platform: 'messenger',
    url: 'fb-messenger://share?link=',
    icon: 'fab fa-facebook-messenger',
    text: 'Via Messenger',
  },
  {
    platform: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
    icon: 'fab fa-facebook-f',
    text: 'On Facebook',
  },
  {
    platform: 'whatsApp',
    url: `${
      isMobile ? 'https://wa.me/?text=' : 'https://api.whatsapp.com/send?text='
    }`,
    icon: 'fab fa-whatsapp',
    text: 'Via WhatsApp',
  },
  {
    platform: 'twitter',
    url: 'http://twitter.com/share?text=',
    url_param: '&url=',
    icon: 'fab fa-twitter',
    text: 'Tweet',
  },
  {
    platform: 'email',
    url: '/flatshare/tellafriend.pl?advert_id=',
    url_param: '&flatshare_type=',
    icon: 'far fa-envelope',
    text: 'Via Email',
  },
];

export default sharePlatforms;
