import trackEvent from '../../helpers/gtm/eventPush';
import get from '../../helpers/data_layer/get';

const ga_page_name = get('page.name');
const label = `${window.location.href}|${ga_page_name}`;
type PageLocation = 'header' | 'footer';

export function track_copy_link({ location }: { location: PageLocation }) {
  trackEvent({
    category: 'sharing',
    action: `copy_link_${location}`,
    label,
  });
}

export function track_share_button({ location }: { location: PageLocation }) {
  trackEvent({
    category: 'sharing',
    action: `share_button_${location}`,
    label,
  });
}

export function track_native_share({ success }: { success: boolean }) {
  trackEvent({
    category: 'sharing',
    action: `browser_share_${success ? 'success' : 'fail'}`,
    label,
  });
}

export function track_fallback_share_click({ platform }: { platform: string }) {
  trackEvent({
    category: 'sharing',
    action: 'fallback_share_click',
    label: `${platform}|${label}`,
  });
}

type SaveAction = 'save' | 'remove';

export function track_save_ad_button({
  save_action,
}: {
  save_action: SaveAction;
}) {
  const action = save_action == 'save' ? 'saved_ads_add' : 'saved_ads_remove';
  trackEvent({
    category: 'narrow',
    action,
    label,
  });
}

type HideAction = 'hide' | 'unhide';

export function track_hide_ad_button({
  hide_action,
}: {
  hide_action: HideAction;
}) {
  trackEvent({
    category: 'narrow',
    action: `ad_${hide_action}`,
    label,
  });
}
