import '../../../sass/apps/reg_prompt/reg_prompt.scss';
import addParametersToURLS from '../../helpers/addParametersToURLs';
import { fetchData } from '../../helpers/base_data_fetcher';
import sdkReporting from '../../services/facebookLogin/sdkReporting';
import triggerExperiment from '../../experiments/reg_prompt_gated_desktop/index.js';

const addTrackingParameterToRegLink = (logRetPath) => {
  const addParametersToLoginReturnPath = addParametersToURLS(logRetPath);
  const loginReturnPath = addParametersToLoginReturnPath({ reg_popup: 1 });
  const regLink = document.getElementById('reg-prompt-gated-register');
  const path = regLink.pathname;
  return (regLink.href = `${path}?loginfrom_url=${encodeURIComponent(
    loginReturnPath,
  )}`);
};

const createPopUp = () => {
  // replace with new facebook_connect in next iteration
  if (window.SR && !SR.facebookConnect.loaded()) {
    SR.facebookConnect.init();
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'ga.event',
      ga_event_action: 'reg_popup_show',
      ga_event_category: 'registration',
      ga_event_label: `blocker`,
    });
  }

  const overlay = document.getElementById('reg_popup_gated_overlay');
  const popUpTarget = document.getElementById('reg_popup_gated');
  overlay.classList.add('overlay-gated--active');
  popUpTarget.classList.add('popreg-gated--active');

  if (window.dataLayer) {
    addGAEvents();
  }
};

const addGAEvents = () => {
  const eventObject = {
    event: 'ga.event',
    ga_event_category: 'registration',
    ga_event_label: `blocker`,
  };
  document.querySelectorAll('.button--facebook').forEach((elem) => {
    elem.onclick = () => {
      if (elem.id == 'fb_login-reg-prompt-gated') {
        sdkReporting(window.FB);
        eventObject.ga_event_action = 'reg_popup_facebook';
        dataLayer.push(eventObject);
      } else {
        dataLayer.push(eventObject);
      }
    };
  });
  document.querySelectorAll('#reg-prompt-gated-register').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_email';
      dataLayer.push(eventObject);
    };
  });

  document.querySelectorAll('#reg_login_gated').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_login';
      dataLayer.push(eventObject);
    };
  });
};

const init = () => {
  fetchData('experiments')
    .then((experiments) => {
      const { reg_popup_ad_details_page_copy_change_desktop } = experiments;
      triggerExperiment(reg_popup_ad_details_page_copy_change_desktop);
    })
    .catch((err) => console.info(err));
  fetchData('page.loginReturnPath')
    .then((loginReturnPath) => addTrackingParameterToRegLink(loginReturnPath))
    .catch((err) => console.info(err));
  return createPopUp();
};
export default init;
