import React, { useState } from 'react';
import '../../../../sass/components/share_buttons.scss';
import { fetchData } from '../../../helpers/base_data_fetcher';
import sharePlatforms from './share_platforms';
import ShareText from './share_text';

// To create a share button with a new platform you need to add the relevant properties
// (i.e. platform name, url, icon, text) of the platform to the share platforms array.
// You may want to add a specific set of styles for that share platform this can be done by
// adding a class (.share-button--"new platform") in share_button.scss and using the cascade to modify any classes
// Then simply add the ShareButton to the Share Panel and pass the necessary props.
// Params: url (required), platform (required), title (optional), text (optional)

function openNewWindow(event, href) {
  const height = 400;
  const width = 550;
  const left = window.screenLeft ? window.screenLeft : window.screenX;
  const top = window.screenTop ? window.screenTop : window.screenY;
  const positionLeft = left + window.innerWidth / 2 - width / 2;
  const positionTop = top + window.innerHeight / 2 - height / 2;
  event.preventDefault();
  window.open(
    href,
    'socialWin',
    `height=${height}, width=${width}, top=${positionTop}, left=${positionLeft}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
  );
  return false;
}

const ShareButton = (props) => {
  const [shareLocalised, setShareLocalised] = useState("");

  fetchData('localisations.social.share')
  .then((shareMsg) => {
   setShareLocalised(shareMsg);
  });

  const sharePlatform = sharePlatforms.find(
    (platform) => platform.platform === props.platform,
  );

  const title = props.title ? props.title : '';
  const params = sharePlatform.url_param ? sharePlatform.url_param : '';

  let url = '';
  if (props.platform == 'email') {
    url = `${sharePlatform.url}${props.advert_id}${params}${encodeURIComponent(
      props.flatshare_type,
    )}`;
  } else {
    url = `${sharePlatform.url}${title}${params}${encodeURIComponent(
      props.url,
    )}`;
  }

  function onClickEvent(event) {
    if (props.onClickCallback) {
      props.onClickCallback();
    }
    if (sharePlatform.platform != 'email') {
      return openNewWindow(event, url);
    }
    return null;
  }

  const shareText = (text) =>
    props.text && typeof props.text === 'boolean' ? (
      <ShareText text={text} />
    ) : props.text && typeof props.text === 'string' ? (
      <ShareText text={props.text} />
    ) : null;

  return (
    <div className="share-button">
      <a
        href={url}
        className={`share-button__button share-button__button--${sharePlatform.platform}`}
        onClick={onClickEvent}
        title={`${sharePlatform.platform} ${shareLocalised}`}
      >
        <div className="share-button__icon">
          <i className={sharePlatform.icon} />
        </div>
        {shareText(sharePlatform.text)}
      </a>
    </div>
  );

};

export default ShareButton;
