import load from './components/loader';
import mapsInterface from '../shared/interface';
import createMapObject from '../shared/mapObject';

const getCoordinates = (latitude, longitude) =>
  new mapkit.Coordinate(parseFloat(latitude), parseFloat(longitude));

function addMarker(
  mapkit,
  {
    map: { map, markers },
    latitude,
    longitude,
    infoWindowHtmlCallBack = () => { return null },
    markerId = null,
    markerIcon = {
      url: '/img/spareroom/v4/search/map/2x/pin.png',
      size: {
        width: 40,
        height: 40,
      },
    },
  } = {},
) {
  const coordinates = getCoordinates(latitude, longitude);

  const annotationObject = {};
  if (markerIcon) {
    const {
      url,
      size: { width, height },
    } = markerIcon;
    annotationObject.url = {
      1: url,
    };
    annotationObject.size = {
      width,
      height,
    };
  }

  const infoWindowHtml = infoWindowHtmlCallBack();
  if (infoWindowHtml) {
    annotationObject.callout = {
      calloutElementForAnnotation: (annotation) => {
        return infoWindowHtml;
      },
    };
  }

  const marker = markerIcon
    ? new mapkit.ImageAnnotation(coordinates, annotationObject)
    : new mapkit.MarkerAnnotation(coordinates, annotationObject);
  if (markerId) {
    markers[markerId] = marker;
  }
  return map.addAnnotation(marker);
}

function removeMarker(mapkit, { map: { map, markers }, markerId }) {
  if (markers[markerId]) {
    map.removeAnnotation(markers[markerId]);
    delete markers[markerId];
  }
}

function createFromCenter(
  mapkit,
  { mountElement, latitude, longitude, spanFrom = 0.01, spanTo = 0.017 },
) {
  const center = getCoordinates(latitude, longitude);
  const map = new mapkit.Map(mountElement, {
    center,
    region: new mapkit.CoordinateRegion(
      // center
      center,
      // span
      new mapkit.CoordinateSpan(spanFrom, spanTo),
    ),
  });
  return createMapObject(map);
}

function createFromRegion(
  mapkit,
  { mountElement, latitude, longitude, latitudeDelta, longitudeDelta },
) {
  const map = new mapkit.Map(mountElement, {
    region: new mapkit.CoordinateRegion(
      // center
      getCoordinates(latitude, longitude),
      // span
      new mapkit.CoordinateSpan(latitudeDelta, longitudeDelta),
    ),
  });
  return createMapObject(map);
}

function onRegionChanged(mapkit, { map: { map }, callback }) {
  const listener = map.addEventListener('region-change-end', (event) => {
    const {
      center: { latitude, longitude },
      span: { latitudeDelta, longitudeDelta },
    } = event.target.region;
    callback({
      latitude,
      longitude,
      latitudeDelta,
      longitudeDelta,
    });
  });
  return listener;
}

function clearOnRegionChanged(mapkit, { map: { map }, listener }) {
  map.removeEventListener('region-change-end', listener);
}

const maps = mapsInterface({
  load,
  createFromRegion,
  createFromCenter,
  addMarker,
  removeMarker,
  onRegionChanged,
  clearOnRegionChanged,
});

export default maps;
