import React from 'react';
import { email } from 'vuelidate/lib/validators';
import Modal from '../../../../components/Modal';
import ShareButton from '../../../../components/buttons/share/share_button';
import CopyLink from '../../../../components/buttons/share/copy_link';
import { track_fallback_share_click } from '../../tracking';


const ShareModal = ({
  showDialog,
  closeFunction,
  shareTitle,
  shareUrl,
  flatshareType,
  advertId,
  modalLabelText,
  copyLinkText,
  emailText,
}) => {
  const url = (platform) =>
    `${shareUrl}?utm_source=${platform}&utm_medium=social_share&utm_campaign=advert_share`;

  return showDialog ? (
    <Modal
      close={closeFunction}
      showDialog={showDialog}
      modalLabelText={modalLabelText}
      id={'share-ad-panel'}
    >
      <div className="share-panel">
        <ShareButton
          platform={'facebook'}
          text={'Facebook'}
          url={url('facebook')}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'facebook' })
          }
          // title={shareTitle}
        />
        <ShareButton
          platform={'messenger'}
          text={'Messenger'}
          url={url('messenger')}
          title={shareTitle}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'messenger' })
          }
        />
        <ShareButton
          platform={'twitter'}
          text={'Twitter'}
          url={url('twitter')}
          title={shareTitle}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'twitter' })
          }
        />
        <ShareButton
          platform={'whatsApp'}
          text={'WhatsApp'}
          url={url('whatsApp')}
          title={shareTitle}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'whatsApp' })
          }
        />
        <CopyLink
          text={copyLinkText}
          copyText={url('copy_link')}
          input={null}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'copy' })
          }
        />
        <ShareButton
          platform={'email'}
          text={emailText}
          url={shareUrl}
          title={shareTitle}
          flatshare_type={flatshareType}
          advert_id={advertId}
          onClickCallback={() =>
            track_fallback_share_click({ platform: 'email' })
          }
        />
      </div>
    </Modal>
  ) : null;
};

export default ShareModal;
