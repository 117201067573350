const uk_desktop_local =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbG9jYWwuc3BhcmVyb29tLmNvLnVrIn0.MlBfa1cmNOgCidaA-C4BmVm7aSlgicX7IIFkMop1OhWK1LvzWsGL0nE6nUf69_qqYJRypbGzRr00gfolShVa6Q';
const uk_desktop_prod =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vd3d3LnNwYXJlcm9vbS5jby51ayJ9.uTkXLHIvQ0aUM6dRfj79Vb3bojZ-mKQAirwU6ubE7Lt9px900j_iafJRnm0jJv6Ad_iigtQ8KYBIw6oZNP-Xdg';
const uk_mobile_local =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbWxvY2FsLnNwYXJlcm9vbS5jby51ayJ9.TchvFIF-mpkDHLIMLODSR7nWwkmkBRGkJyTEFMj8PnF5wez4dwg42Vb6PZUztGAYzZA2FwKf0a-gVXmxyjJ8dw';
const uk_mobile_prod =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbS5zcGFyZXJvb20uY28udWsifQ.tggf2vKVN-aTKcsxgPfxfIOL2xpVdZhHjIMQzf-XFYgE40uwzJR4sWx-RajgYbrpC-vqQ0tX0oN7Zb66imQiCA';

const us_desktop_local =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbG9jYWwuc3BhcmVyb29tLmNvbSJ9.qVsXVzGQ9xGTaoUArWjGRiA78-pqq4o028viTioCSH65BGJw5FGYHAgV6yYITB0ryAailJMvLcaSVNSXTj-mqQ';
const us_desktop_prod =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vd3d3LnNwYXJlcm9vbS5jb20ifQ.1s2RyfezYWknghPhivRhL65jKzCCcJzpmH8r8oAXjylyJWKJxtRIBb8jxrJOmnNviK5SLB84wusrDUbkQEASbA';
const us_mobile_local =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbWxvY2FsLnNwYXJlcm9vbS5jb20ifQ.Jtr_LWTkyIPoNBICHo12plxry0OmzvL59E6fAVhAH3dDYqfzaAjC_MBbMVzmtSafSy-8m3qdgXyjlkf_T30qAg';
const us_mobile_prod =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlozTUZWUjhGRzUifQ.eyJpc3MiOiI1RUE4VjI3OUJQIiwiaWF0IjoxNTc0MTU4MTY4LCJleHAiOjE3Mzc1NDA1NjgsIm9yaWdpbiI6Imh0dHBzOi8vbS5zcGFyZXJvb20uY29tIn0.sgQWmEfRxCjxesmJbD7moC2RwQp2etz3KPx0Yi_0fgvMIM_kCAP6OiRknhIge01aHHDizqsaGFgNt8jgEGNzzA';

const map = {
  'https://local.spareroom.co.uk': uk_desktop_local,
  'https://www.spareroom.co.uk': uk_desktop_prod,
  'https://mlocal.spareroom.co.uk': uk_mobile_local,
  'https://m.spareroom.co.uk': uk_mobile_prod,
  'https://local.spareroom.com': us_desktop_local,
  'https://www.spareroom.com': us_desktop_prod,
  'https://mlocal.spareroom.com': us_mobile_local,
  'https://m.spareroom.com': us_mobile_prod,
};

export default function(base_url) {
  return map[base_url];
}
