import getActiveExperimentVariant from '../helpers/optimize';

function copyChange() {
  const copy = document.querySelector('.popreg__content-wrapper h2');
  if (copy) {
    copy.textContent = 'You’ve viewed 4 ads, register now for free to see more';
  }
}

async function triggerExperiment(
  reg_popup_ad_details_page_copy_change_desktop,
) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'optimize.activate.reg_popup_ad_details_page_copy_change_desktop',
    });
    try {
      const variant = await getActiveExperimentVariant(
        reg_popup_ad_details_page_copy_change_desktop,
      );
      if (variant != 0) {
        copyChange();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
}

export default triggerExperiment;
