import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../components/Modal';

const ReportAdModal = ({ surveyHref, modalTitle }) => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const activateButtons = document.querySelectorAll('.report-ad-modal-link');

    activateButtons.forEach((button) => {
      button.addEventListener('click', () => {
        setShowDialog(true);
      });
    });
  }, []);

  const close = () => setShowDialog(false);

  return (
    <Modal
      close={close}
      showDialog={showDialog}
      modalLabelText={modalTitle}
      id={'report-ad'}
      fullBleed={true}
    >
      <iframe
        className="report-ad__wrapper"
        frameBorder="0"
        allowtransparency="true"
        src={surveyHref}
      ></iframe>
    </Modal>
  );
};

export default function renderReportAdModal() {
  const mountElement = document.getElementById('reportAdModal');
  if (mountElement) {
    const surveyHref = mountElement.getAttribute('data-iframe-href');
    const modalTitle = mountElement.getAttribute('data-modal-title');
    ReactDOM.render(<ReportAdModal surveyHref={surveyHref} modalTitle={modalTitle} />, mountElement);
  }
}
