import getToken from './tokens';

function initialiseApp(mapkit, { JWT }) {
  return mapkit.init({
    authorizationCallback: function(done) {
      done(JWT);
    },
  });
}

async function load({ JWT, base_url } = {}) {
  if (!JWT) {
    JWT = getToken(base_url);
  }
  if (window.mapkit) {
    return Promise.resolve(window.mapkit);
  }
  if (!window.mapkit) {
    try {
      await createMapKitTag();
    } catch (error) {
      return error;
    }
  }
  try {
    initialiseApp(window.mapkit, { JWT });
    return window.mapkit;
  } catch (error) {
    return error;
  }
}

function createMapKitTag() {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js`;
    scriptTag.id = 'apple-mapkit-tag';
    scriptTag.defer = true;
    scriptTag.onerror = () =>
      reject(new Error('Apple MapKit JS Failed to load'));
    scriptTag.onload = () => resolve();
    return document.head.appendChild(scriptTag);
  });
}

export default load;
