function fallbackCopyToClipboard(string) {
  const textarea = document.createElement('textarea');
  textarea.value = string;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  return true;
}

export type CopyArgs = {
  text: string;
  successCallback?: () => void;
};
export default async function copyTextToClipboard({
  text,
  successCallback,
}: CopyArgs) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  } else {
    fallbackCopyToClipboard(text);
    if (successCallback) {
      successCallback();
    }
  }
}
