import storageAvailable from '../../../helpers/storage_available';
// stores the currently viewed profile to local storage using the key last_viewed_{customer|property}_profile

type SR = {
  ecommerce: {
    detail: {
      products: any[];
    };
  };
};

declare const _sr: SR;

export default function storeLastViewedListing(
  listingType: 'offered' | 'wanted',
) {
  if (storageAvailable('localStorage') && _sr.ecommerce.detail) {
    const profile_type = listingType == 'offered' ? 'property' : 'customer';
    const lastViewedProfile = {
      date_stored: Date.now(),
      profile: _sr.ecommerce.detail.products[0],
    };
    return localStorage.setItem(
      `last_viewed_${profile_type}_profile`,
      JSON.stringify(lastViewedProfile),
    );
  }
  return false;
}

