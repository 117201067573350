import load from './components/loader';
import mapsInterface from '../shared/interface';
import createMapObject from '../shared/mapObject';

const getLatLangObject = (latitude, longitude) => ({
  lat: parseFloat(latitude),
  lng: parseFloat(longitude),
});

const getCornersObject = ({
  latitude,
  longitude,
  latitudeDelta,
  longitudeDelta,
}) => {
  const coords = {
    minLat: parseFloat(latitude) - parseFloat(latitudeDelta) / 2,
    minLon: parseFloat(longitude) - parseFloat(longitudeDelta) / 2,
    maxLat: parseFloat(latitude) + parseFloat(latitudeDelta) / 2,
    maxLon: parseFloat(longitude) + parseFloat(longitudeDelta) / 2,
  };
  return {
    southWest: getLatLangObject(coords.minLat, coords.minLon),
    northEast: getLatLangObject(coords.maxLat, coords.maxLon),
  };
};

function createFromCenter(googleMaps, { mountElement, latitude, longitude }) {
  const map = new googleMaps.Map(mountElement, {
    center: getLatLangObject(latitude, longitude),
    zoom: 14,
  });
  return createMapObject(map);
}

function createFromRegion(
  googleMaps,
  { mountElement, latitude, longitude, latitudeDelta, longitudeDelta },
) {
  const map = new googleMaps.Map(mountElement, {
    center: getLatLangObject(latitude, longitude),
  });
  const { southWest, northEast } = getCornersObject({
    latitude,
    longitude,
    latitudeDelta,
    longitudeDelta,
  });
  const bounds = new googleMaps.LatLngBounds(southWest, northEast);
  map.fitBounds(bounds);
  return createMapObject(map);
}

function addMarker(
  googleMaps,
  {
    map: { map, markers, infoWindow },
    latitude,
    longitude,
    infoWindowHtml = null,
    markerId = null,
    markerIcon = {
      url: '/img/spareroom/v4/search/map/2x/pin.png',
      size: {
        width: 40,
        height: 40,
      },
    },
  } = {},
) {
  const coordinates = getLatLangObject(latitude, longitude);

  const markerOptions = {
    position: coordinates,
    map,
  };

  if (markerIcon) {
    const {
      url,
      size: { width, height },
    } = markerIcon;
    markerOptions.icon = {
      url,
      scaledSize: new googleMaps.Size(width, height),
    };
  }

  const marker = new googleMaps.Marker(markerOptions);
  if (infoWindowHtml) {
    if (
      !infoWindow.instance ||
      !(infoWindow.instance instanceof googleMaps.InfoWindow)
    ) {
      infoWindow.instance = new googleMaps.InfoWindow();
    }
    marker.addListener('click', function() {
      infoWindow.instance.setContent(infoWindowHtml);
      infoWindow.instance.open(map, marker);
    });
    map.addListener('click', function() {
      infoWindow.instance.close(map, marker);
    });
  }
  if (markerId) {
    markers[markerId] = marker;
  }

  return marker;
}

function removeMarker(googleMaps, { map: { markers }, markerId }) {
  if (markers[markerId]) {
    markers[markerId].setMap(null);
    delete markers[markerId];
  }
}

function returnNewRegion(map, callback) {
  const centre = map.getCenter();
  const bounds = map.getBounds();
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();
  const latitudeDelta = northEast.lat() - southWest.lat();
  const longitudeDelta = northEast.lng() - southWest.lng();
  return callback({
    latitude: centre.lat(),
    longitude: centre.lng(),
    latitudeDelta,
    longitudeDelta,
  });
}

function onRegionChanged(googleMaps, { map: { map }, callback }) {
  const dragListener = map.addListener('dragend', (event) => {
    returnNewRegion(map, callback);
  });
  const zoomListener = map.addListener('zoom_changed', (event) => {
    returnNewRegion(map, callback);
  });
  return [dragListener, zoomListener];
}

function clearOnRegionChanged(googleMaps, { listener: listeners }) {
  listeners.forEach((listener) => googleMaps.event.removeListener(listener));
}

const maps = mapsInterface({
  load,
  createFromRegion,
  createFromCenter,
  addMarker,
  removeMarker,
  onRegionChanged,
  clearOnRegionChanged,
});

export default maps;
