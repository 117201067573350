  window.SR.overlay = (function(module) {
    const markup = function(images) {
      let html = '<div class="photo-lightbox__slides-wrapper">';
      const num = images.length;
      let i = 0;

      for (; i < num; i += 1) {
        html += '<div class="photo-lightbox__slide">';
          html += '<header class="photo-lightbox__header">';
            html += '<div class="photo-lightbox__title-wrapper">';
              html += `<h3 class="photo-lightbox__title">${htmlEncode(images[i].adtitle)}</h3>`;
              html += `<p class="photo-lightbox__subtitle">${htmlEncode(images[i].subtitle)}</p>`;
            html += '</div>';
            html += `<div class="photo-lightbox__price-wrapper">${images[i].pricepre} <span class="photo-lightbox__price">${images[i].price}</span> ${images[i].per}</div>`;
          html += '</header>';
        if (images[i].mediatype == 'video') {
          html += `<iframe width="640" height="480" src="https://www.youtube.com/embed/${images[i].youtubeid}" frameborder="0" allowfullscreen></iframe>`;
        } else {
          html += `<img class="photo-lightbox__image" src="${images[i].url}">`;
          html += `<div class="photo-lightbox__caption">${htmlEncode(images[i].caption)}</div>`;
        }
        html += '</div>';
      }

      if (num > 1) {
        html +=
          '<a class="photo-lightbox__arrow photo-lightbox__arrow--previous"></a>' +
          '<a class="photo-lightbox__arrow photo-lightbox__arrow--next"></a>';
      }

      html += '</div>';

      return html;
    };

    const Gallery = function(images) {
      SR.overlay.Overlay.call(this, {
        classes: 'photo-lightbox',
        content: markup(images),
      });

      this.activeImage = 0;
      this.noOfImages = images.length;
      this.images = this.lightboxEl.querySelectorAll('.photo-lightbox__slide');


      const prevArrow = this.lightboxEl.querySelector('.photo-lightbox__arrow--previous');
      const nextArrow = this.lightboxEl.querySelector('.photo-lightbox__arrow--next')
      if (prevArrow) {
        prevArrow.addEventListener('click', () => {
          this.prev();
        })
      }
      if (nextArrow) {
        nextArrow.addEventListener('click', () => {
          this.next();
        })
      }

      this.overlayEl.classList.add("loading");

      
      this.imagesdata = images;

      const _self = this;

      document.addEventListener('keydown', (e) => {
        const prevArrow = document.querySelector('.photo-lightbox__arrow--previous');
        if (prevArrow.offsetParent != null) {
          if(e.keyCode == 37) { 
            Gallery.prototype.prev.call(_self);
          }
          else if(e.keyCode == 39) { 
            Gallery.prototype.next.call(_self);
          }
        }
      });
    };


    Gallery.prototype = new SR.overlay.Overlay();
    
    Gallery.prototype.prev = function() {
      this.show(this.activeImage - 1);
    };

    Gallery.prototype.next = function() {
      this.show(this.activeImage + 1);
    };

    Gallery.prototype.show = function(index) {
      let image;

      index = index + 1 > this.noOfImages ? 0 : index;
      index = index < 0 ? this.noOfImages - 1 : index;
      this.activeImage = index;
      

      const imageElement = this.images[index];

      this.images.forEach((image) => {
        image.style.display = "none";
      })

      imageElement.style.display = "block";
      this.open();

      if (this.imagesdata[index].mediatype == 'video') {
        this.autoFit(640, 480);
      } else {
        image = new Image();
        image.src = imageElement.querySelector('.photo-lightbox__image').src;
      }
    };
    

    function htmlEncode (string) {
      return string.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
        return '&#'+i.charCodeAt(0)+';';
      });
    }

    module.Gallery = Gallery;
    return module;
  })(window.SR.overlay || {});
