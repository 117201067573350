import React, { useState } from 'react';
import ShareModal from './ShareModal';
import { track_share_button } from '../../tracking';

const ShareFallback = ({ shareUrl, shareTitle, flatshareType, advertId, modalLabelText, copyLinkText, emailText }) => {
  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
  function handleClose() {
    // return focus from whence it came
    setPanelIsOpen(false);
  }
  function handleShareButtonClick() {
    const { trackingLocation } = this.dataset;
    track_share_button({ location: trackingLocation });
    // do we need to capture the click element to restore focus on close?
    setPanelIsOpen(true);
  }
  const shareButtons = document.querySelectorAll('[data-share-button]');
  shareButtons.forEach((button) => {
    button.addEventListener('click', handleShareButtonClick);
  });
  return (
    <ShareModal
      shareUrl={shareUrl}
      shareTitle={shareTitle}
      showDialog={panelIsOpen}
      closeFunction={handleClose}
      flatshareType={flatshareType}
      advertId={advertId}
      modalLabelText={modalLabelText}
      copyLinkText={copyLinkText}
      emailText={emailText}
    />
  );
};

export default ShareFallback;
