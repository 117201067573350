export default function mapsInterface({
  load,
  createFromCenter,
  createFromRegion,
  addMarker,
  removeMarker,
  onRegionChanged,
  clearOnRegionChanged,
}) {
  const requiredArgs = [
    'load',
    'createFromCenter',
    'createFromRegion',
    'addMarker',
    'removeMarker',
    'onRegionChanged',
    'clearOnRegionChanged',
  ];
  // disabling as this is being used to perform a rigorous check on the arguments, rather than to use them.
  /* eslint-disable */
  const argumentObject = [...arguments][0];
  /* eslint-enable */
  if (
    !(argumentObject instanceof Object && argumentObject.constructor === Object)
  ) {
    throw new Error(
      `Map interface requires a sole object to be passed as it's only argument with the following methods: ${requiredArgs.toString()}`,
    );
  }
  const keys = Object.keys(argumentObject);
  requiredArgs.forEach((argument) => {
    if (argumentObject[argument] == undefined) {
      throw new Error(
        `Map interface requires a sole object to be passed as it's only argument with the following methods: ${requiredArgs.toString()}`,
      );
    }
  });
  const incorrectTypes = {};
  keys.forEach((key) => {
    const type = typeof argumentObject[key];
    if (type !== 'function') {
      return (incorrectTypes[key] = type);
    }
    return true;
  });
  const incorrectKeys = Object.keys(incorrectTypes);
  if (incorrectKeys.length) {
    const incorrectString = incorrectKeys.reduce((result, key) => {
      if (result != '') {
        result += `, ${key}: ${incorrectTypes[key]}`;
      } else {
        result = `${key}: ${incorrectTypes[key]}`;
      }
      return result;
    }, '');
    throw new Error(
      `All properties on the map interface argument object must be of type function. Incorrect properties: ${incorrectString}`,
    );
  }
  return {
    load,
    createFromRegion,
    createFromCenter,
    addMarker,
    removeMarker,
    onRegionChanged,
    clearOnRegionChanged,
  };
}
