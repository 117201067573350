window.SR = (function( module) {
	'use strict';

	/**
	 * Defaults for all types of overlay.
	 */

	var defaults = {
		classes: '',
		content: '',
		width:   640,
		height:  480
	},

	/**
	 * Construct a new Overlay.
	 * Create the necessary DOM elements and events for an overlay.
	 *
	 * @constructor
	 * @param {object} options
	 * @api public
	 */

	Overlay = function( options ) {
		if ( !arguments.length ) return;

		options = Object.assign(defaults, options);

		this.overlayEl = document.createElement('div');
		this.overlayEl.classList.add('overlay');
		this.overlayEl.addEventListener('click', () => {
			this.close();
		});

    this.lightboxEl = document.createElement('div');
		this.lightboxEl.classList.add('lightbox');
		this.lightboxEl.addEventListener('click', (event) => {
			event.stopPropagation();
		});

		this.contentEl = document.createElement('div');
		this.contentEl.classList.add('content');
		this.contentEl.innerHTML = options.content;

	
		this.closeEl = document.createElement('a');
		this.closeEl.classList.add('overlay_close');
		this.closeEl.innerText = 'x';
		this.closeEl.addEventListener('click', () => {
			this.close();
		});

		this.lightboxEl.append(this.closeEl)
		this.lightboxEl.append(this.contentEl);

		this.overlayEl.classList.add(options.classes)
		this.overlayEl.append(this.lightboxEl);
			

		document.body.append(this.overlayEl);
		document.addEventListener('keydown', (e) => {
			if (e.key == "Escape") {
				this.close();
			}
		});
	
		this.setDimensions( options.width, options.height );
	},

	/**
	 * An Ajax overlay has the same prototype as a normal Overlay,
	 * with the added functionality of loading of specific external content.
	 * When constructing, load the URL by default.
	 *
	 * @constructor
	 * @param {object} options to override the defaults with
	 * @param {function} callback fired when the url is loaded
	 * @api public
	 */

	Ajax = function( options, callback ) {
		Overlay.call( this, options );
		this.load( options.url, callback );
	},

	/**
	 * An Iframe overlay has the same prototype as a normal Overlay,
	 * with the added functionality of loading iFrame content.
	 * When constructing, load the URL by default.
	 *
	 * @constructor
	 * @param {object} options to override the defaults with
	 * @param {function} callback fired when the iframe is loaded
	 * @api public
	 */

	Iframe = function( options, callback ) {
		Overlay.call( this, options );
		this.load( options.url, callback );
	};

	/**
	 * Activates this Overlay.
	 * Re-center the lightbox, because the viewport may have scrolled.
	 */

	Overlay.prototype.open = function() {
		this.overlayEl.classList.remove('overlay_inactive');
		this.overlayEl.classList.add('overlay_active');
		this.center();
	};

	/**
	 * Deactivates this Overlay
	 */

	Overlay.prototype.close = function() {
		this.overlayEl.classList.remove('overlay_active');
		this.overlayEl.classList.add('overlay_inactive');
	};

	/**
	 * Removes this Overlay from the DOM
	 */

	Overlay.prototype.destroy = function() {
		this.overlayEl.remove();
	};

	/**
	 * Sets this Overlay's lightbox dimensions
	 *
	 * @param {number} width
	 * @param {number} height
	 */

	Overlay.prototype.setDimensions = function( width, height ) {
		this.lightboxEl.style.height = `${height}px`;
		this.lightboxEl.style.width = `${width}px`;
	};

	/**
	 * Centers the Overlay's lightbox in the middle of the viewport
	 * If position fixed isn't supported, add the scroll offset.
	 */

	Overlay.prototype.center = function() {
		const top = Math.ceil( ( window.innerHeight - this.lightboxEl.clientHeight ) / 2 );
		const left = Math.ceil( ( window.innerWidth  - this.lightboxEl.clientWidth  ) / 2 );

		if ( this.overlayEl.position === 'absolute' ) {
			top  += window.scrollTop();
			left += window.scrollLeft();
		}

		this.lightboxEl.style.marginTop = `${top}px`;
		this.lightboxEl.style.marginLeft = `${left}px`;
	};

	/**
	 * - Use this to squeeze a lightbox into the viewport
	 * - Find the difference between the dimensions passed in and
	 *   the amount of available screen space.
	 * - e.g. If the available width is the smallest dimension, then we must
	 *   endevour to fit within this. Or, if the available height is the
	 *   smallest dimension, then we must fit it within that.
	 *   Without touching the edges!
	 * - Re-center the lightbox
	 *
	 * @param {number} width
	 * @param {number} height
	 */

	Overlay.prototype.autoFit = function( width, height ) {
		var availWidth     = window.innerWidth,
			availHeight    = window.innerHeight,
			fitWithin      = availWidth,
			scaleDimension = width,
			ratio;
			
		if ( availHeight <= availWidth ) {
			scaleDimension = height;
			fitWithin      = availHeight;
		}

		ratio  = fitWithin / ( scaleDimension + ( fitWithin * 0.5 ) );
		width  = Math.ceil( width  * ratio );
		height = Math.ceil( height * ratio );

		this.setDimensions( width, height );
		this.center();
	};

	/**
	 * Define how all overlays should work:
	 */

	Ajax.prototype   = new Overlay();
	Iframe.prototype = new Overlay();

	/**
	 * An Ajax overlay's version of how to load content into itself.
	 *
	 * @param {string} url of content to load, can also have a selector #element
	 * @param {function callback fired when the content is loaded.
	 * @return {object} this to allow method chaining
	 */

	Ajax.prototype.load = function( url, callback ) {
		var me = this;

		this.overlayEl.classList.add('loading');

		this.contentEl.load( url, function() {
			me.overlayEl.classList.remove('loading');
			callback && callback.call(me);
		});

		return this;
	};

	/**
	 * An Iframe overlay's version of how to load content into itself.
	 *
	 * @param {string} url of content to load
	 * @param {function} callback fired when the content is loaded.
	 * @return this to allow method chaining
	 */

	Iframe.prototype.load = function( url, callback ) {
		var me = this;

		this.overlayEl.classList.add('loading');

		const iFrameEl = document.createElement('iframe');
		iFrameEl.frameBorder = 0;
		iFrameEl.classList.add('content');
		iFrameEl.src = url;
		iFrameEl.load = function() {
			me.overlayEl.classList.remove('loading');
			callback && callback.call(me);
		};
		this.contentEl.append(iFrameEl);

		return this;
	};

	/**
	 * Expose the overlay API
	 */

	module.overlay = {
		Overlay: Overlay,
		Ajax:    Ajax,
		Iframe:  Iframe
	};

	return module;

}( window.SR || {}));